.app-container {
  min-height: 100vh;
}

.app-container .view-container .card {
  padding-bottom: 90px;
}

em {
  font-weight: normal;
}

.w20 {
  width: 20%;
}

h2 {
  font-size: 30px;
  font-weight: normal;
  margin-top: 6px;
}

#mainlogo {
  width: 300px;
  height: auto;
  margin-bottom: 20px;
}

a {
  font-weight: normal;
  transition: all 0.3s;
}

.sk-layout {
  background-color: #fff;
}

.sk-search-box {
  border: #777 1px;
  border-style: solid;
  border-radius: 4px;
}

.result th {
  vertical-align: top;
}

.sk-layout__body .sk-hits.is-12 a {
  color: #2b5a9b;
  text-decoration: underline;
}

.sk-layout__body .btn {
  font-family: 'Source Sans Pro', sans-serif;
}

.sk-layout__body .btn-primary span {
  color: #fff;
}

.sk-layout__body .list_hit {
  margin-bottom: 10px;
  padding: 2px 4px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.list_hit .btn {
  margin-right: 4px;
  margin-bottom: 4px;
}

.btn-sm,
.btn-group-sm .btn,
.card .btn {
  border-radius: 2px;
  font-size: 14px;
}

.form-control {
  font-size: 14px;
  padding: 2px 5px;
  line-height: 1.3;
}

.btn-primary {
  background-color: #2b5a9b;
  border-color: #2b5a9b;
}

.btn-secondary {
  background-color: #eee;
  border-color: #ddd;
}

.btn-secondary:hover {
  background-color: #ddd;
  border-color: #ddd;
}

.btn-primary:hover,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:active {
  background-color: #08c;
}

.sk-layout__filters {
  box-shadow: none;
}

.searchmod > div {
  display: inline-block;
}

.sk-layout__results {
  box-shadow: none;
}

.sk-search-box input.sk-search-box__text {
  color: #000;
}

.popover {
  max-width: 1024px;
}

.langsel {
  display: inline;
}
.langsel .btn {
  font-size: 0.75em;
  font-weight: bold;
}

.caption {
  font-weight: bold;
}

.alternating {
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.alternating .row:nth-child(even) {
  background: rgba(0, 0, 0, 0.05);
}

.row {
  padding: 8px 0;
  padding-bottom: 0;
  padding-top: 0;
  margin-bottom: 0;
  margin-top: 0;
}

.read-more-button {
  display: contents;
}

.app-container .view-container .card {
  padding: 0 0 90px 0;
}

.card {
  border: none;
}

.card-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.card-group .sk-hits {
  flex: 1 1 auto;
}

.sk-hits .card {
  margin: 0 5px 15px 5px;
  border: 1px solid #eee;
}

.card-title {
  margin: 0;
  padding: 2px 4px;
  font-size: 15px;
  font-weight: bold;
  color: #222;
}

.card-body > div {
  background-color: #eee;
}

.card-body {
  padding: 1rem;
}

.card-group .sk-hits .card,
.card-group .sk-hits .card-body,
.card-group .sk-hits .card-header {
  padding: 0;
}

.card-group .sk-hits .langsel {
  display: inline-block;
}

.card-group .sk-hits .langsel .btn {
  font-size: 14px;
  font-weight: normal;
  padding: 0.25rem 0.5rem;
}

.card-text {
  font-size: 14px;
  line-height: 1.2;
  padding: 5px;
  overflow-y: auto;
}

ul.card-text {
  padding-left: 25px;
}

.qtext {
  height: 70px;
  margin: 0;
  padding: 5px;
}

.qtextmed {
  height: 50px;
  padding: 5px;
  margin: 0;
}

.qtextshort {
  height: 24px;
  line-height: 24px;
  padding: 0 5px;
  margin: 0;
}

.qtext li:empty {
  display: none;
}

.fieldSelector {
  padding: 10px;
  text-align: center;
}

.searchBoxQuestionDetail {
  display: none;
}

.searchBoxStudyDetail {
  display: none;
}

.notPrefferedLang {
  color: #999999;
}

.sk-pagination-navigation {
  margin-bottom: 80px;
}

.clickTip {
  color: white;
  pointer-events: auto !important;
}
.clickTip:hover {
  visibility: visible !important;
  opacity: 1 !important;
}

.clickTip a {
  color: white;
  font-weight: 800;
}

.hideForUser {
  display: none;
}

.bbgrey {
  padding-bottom: 10px;
  margin-left: 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.hideLangKey {
  display: none;
}

.form-inline {
  display: block;
}

.form-inline .form-control {
  min-width: 100px;
  display: inline-block;
  width: 60%;
}

.sk-panel__header {
  margin: 5px 0;
}

.sk-toggle-option.is-active {
  background-color: #2b5a9b;
  color: #fff;
  border-color: #2b5a9b;
}

.sk-toggle {
  margin-bottom: 6px;
}

.sk-layout__filters {
  order: 2;
}

.sk-layout__results {
  order: 1;
}

.visible-md {
  display: none;
}

@media only screen and (min-width: 800px) {
  .sk-layout__filters {
    order: 1;
  }

  .sk-layout__results {
    order: 2;
  }

  .card-group .sk-hits {
    width: 50%;
  }

  .form-inline {
    display: flex;
  }

  .visible-md {
    display: inline-block;
  }

  .hidden-md {
    display: none;
  }

  .form-inline .form-control {
    min-width: 300px;

    width: 60%;
  }
}

@media only screen and (min-width: 1180px) {
  .card-group .sk-hits {
    width: 33%;
  }
}

@media only screen and (min-width: 1600px) {
  .card-group .sk-hits {
    width: 25%;
    max-width: 25%;
  }
}

.list_hit .btn {
  margin-right: 4px;
  margin-bottom: 4px;
}

.filter--questionTextPaths .sk-panel__header {
  display: none;
}

.filter--studyTitlesPaths .sk-panel__header {
  display: none;
}

.filter--prePostPaths .sk-panel__header {
  display: none;
}

.filter--responseCategoriesPaths .sk-panel__header {
  display: none;
}

.sk-layout__filters .sk-filter-group__remove-action {
  display: none;
}

.hidden_de {
  display: none;
}

.hidden_en {
  display: none;
}

[class$='count'] {
  color: black;
  font-weight: bold;
}

.list span {
  display: inline-block;
  border-right: 1px solid #000; /* amend as needed */
  padding-right: 10px;
  margin-right: 10px;
}

.list span:last-child {
  border: 0;
  padding: 0;
  margin: 0;
}

.contributors-title-list {
  padding: 0;
  margin: 0;
}

.contributors-title-list li:after {
  content: ' |';
}
.contributors-title-list li {
  display: inline-block;
  padding-right: 3px;
  margin-right: 3px;
}

.contributors-title-list li:last-child:after {
  content: '';
}

.footer-list {
  padding: 0;
  margin: 0;
}

.footer-list li {
  display: inline-block;
  border-right: 1px solid #000; /* amend as needed */
  padding-right: 3px;
  margin-right: 3px;
}

.footer-list li:last-child {
  border: 0;
  padding: 0;
  margin: 0;
}
.footer-list {
  font-size: xx-small;
  margin: 0;
  padding: 0;
}
.hittitle {
  line-height: 0;
  padding: 0;
  margin: 0;
  margin-left: 12px;
  color: #4b4b4d;
  font-weight: 500;
}

.hittitle h3 {
  padding: 0;
  margin-bottom: 10px;
}
.header {
  margin: 0;
  margin-bottom: 10px;
}

.key {
  padding-right: 40px;
  padding-bottom: 0px;
}

a:link,
a:visited {
  text-decoration: none;
}

a {
  color: #375b9a;
}

.badge,
.badge:active,
.badge:visited {
  background-color: #3f80ff !important;
  border-color: #3f80ff;
}

.badge:hover {
  background-color: #0a58ca !important;
  border-color: #0a58ca;
  color: white;
}

.subTable {
  width: 100%;
}

.accordion-button.collapsed {
  cursor: pointer;
  color: #375b9a;
  text-decoration: underline;
}

.inline-list {
  padding: 0;
  margin: 0;
}

.inline-list li {
  display: inline-block;
  border-right: 1px solid #000; /* amend as needed */
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-right: 3px;
  margin-right: 3px;
}

.inline-list li:last-child {
  border: 0;
  padding: 0;
  margin: 0;
}

.filterTable {
  font-size: small;
  word-break: break-all;
}

.deleteAllFilter {
  background-color: #eceff4;
}
.close-btn {
  position: absolute;
  right: 20px;
  top:0px;
  padding:0px;
  margin:0px;
}

.star-row {
  padding-right:15px;
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom:10px;
  text-align: right;
}

.metadataAccordion.accordion:has(.accordion-item > .accordion-collapse > .accordion-body > table > tbody:empty) {
  display: none;
}

.projectNotes , .sampling , .abstract {
  white-space: pre-wrap;
}

element {
}

.message{
  text-align: center;
  position: absolute;
  top: 130px;
  left: 0;
  width: 100%;
  height: 100%;
  color: black;
  z-index: 1;
  background: white;
}

.message img {
  width:15px;
}

.publisherIcon  {
  max-height:30px;
}

.smallValue {
  font-size: x-small;
}